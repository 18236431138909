import { createRouter, createWebHashHistory } from 'vue-router'
// import About from '../views/About.vue'
const About = () => import(/* webpackChunkName: "about" */ '../views/About.vue')
import EventList from '../views/EventList.vue'
import EventLayout from '@/views/event/Layout.vue'
import EventDetails from '@/views/event/Details.vue'
import EventRegister from '@/views/event/Register.vue'
import EventEdit from '@/views/event/Edit.vue'
import NotFound from '@/views/NotFound.vue'
import NetworkError from '@/views/NetworkError.vue'
import NProgress from 'nprogress'
import EventService from '@/services/EventService.js'
import GStore from '@/store'

const routes = [
  {
    path: '/',
    name: 'EventList',
    component: EventList,
    props: route => ({ page: parseInt(route.query.page) || 1 })
  },
  {
    path: '/events/:id',
    name: 'EventLayout',
    props: true,
    component: EventLayout,
    beforeEnter: to => {
        return EventService.getEvent(to.params.id)
            .then(response => {
                console.log('event: ', response.data)
                GStore.event = response.data
            })
            .catch(error => {
                console.log(error)

                if (error.response && error.response.status == 404) {
                    return { name: '404Resource', params: { resource: 'event' } }
                } else {
                    return { name: 'NetworkError' }
                }
            })
    },
    children: [
        {
            path: '',
            name: 'EventDetails',
            component: EventDetails
        },
        {
            path: 'register',
            name: 'EventRegister',
            component: EventRegister
        },
        {
            path: 'edit',
            name: 'EventEdit',
            component: EventEdit,
            meta: { requireAuth: true }
        }
    ]
  },
//   {
//     path: '/event/:id',
//     redirect: () => {
//         return { name: 'EventDetails' }
//     },
//     children: [
//         { path: 'register', redirect: () => ({ name: 'EventRegister' }) },
//         { path: 'edit', redirect: () => ({ name: 'EventEdit' }) }
//     ]
//   },
  {
    path: '/event/:afterEvent(.*)',
    redirect: to => {
      return { path: '/events/' + to.params.afterEvent }
    }
  },
  {
    path: '/about',
    alias: '/whoweare',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ '../views/About.vue')
    component: About
  },
  {
      path: '/about-us',
      redirect: { name: 'About' }
  },
  {
      path: '/:catchAll(.*)',
      name: 'NotFound',
      component: NotFound,
      meta: {
        requiresAuth: false
      }
  },
  {
      path: '/404/:resource',
      name: '404Resource',
      component: NotFound,
      props: true
  },
  {
      path: '/network-error',
      name: 'NetworkError',
      component: NetworkError
  }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    }
});

router.beforeEach((to, from) => {
    NProgress.start()

    const notAuthorized = true
    if (to.meta.requireAuth && notAuthorized) {
        GStore.flashMessage = 'Sorry, you are not authorized to view this page'
        setTimeout(() => { GStore.flashMessage = '' }, 3000)

        if (from.href) {
            return false
        } else {
            return { path: '/' }
        }
    }
})

router.afterEach(() => {
    NProgress.done()
})

export default router;
