<template>
  <p>Register for the event here!</p>
  <button @click="register">Register Me</button>
</template>

<script>
export default {
    inject: [ 'GStore' ],
    props: [ 'event' ],
    methods: {
        register () {
            // Assuming successful API call to register them

            // Set a flash message to appear on the next page loaded which says
            // 'You are successfully registered for ' + this.event.title
            this.GStore.flashMessage = 'You are successfully registered for ' + this.event.title
            setTimeout(() => {
                // After 3 seconds remove it
                this.GStore.flashMessage = ''
            }, 3000)

            // Call API
            // If registered
            this.$router.push({
                name: 'EventDetails',
                params: { id: this.event.id }
            })
            // this.$router.replace() -- instead of .push()
            // this.$router.go() -- .go(1) for forwarding, .go(-1) for backwarding
        }
    }
}
</script>
