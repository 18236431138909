<template>
  <h1>Events for Good</h1>
  <div class="events">
    <EventCard
      v-for="event in events"
      :key="event.id"
      :event="event"
    />

    <div>{{ page }}</div>

    <div class="pagination">
      <router-link
        v-if="page > 1"
        id="page-prev"
        :to="{ name: 'EventList', query: { page: page - 1 } }"
        rel="prev"
      >
        Prev Page
      </router-link>

      <router-link
        v-if="hasNextPage"
        id="page-next"
        :to="{ name: 'EventList', query: { page: page + 1 } }"
        rel="next"
      >
        Next Page
      </router-link>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
import EventService from '@/services/EventService.js'

export default {
    name: 'EventList',
    components: {
        EventCard
    },
    beforeRouteEnter (routeTo, routeFrom, next) {
        EventService.getEvents(2, parseInt(routeTo.query.page) || 1)
            .then(response => {
                next(comp => {
                    console.log('events: ', response.data)
                    comp.events = response.data
                    comp.totalEvent = response.headers['x-total-count'] // Told by the db
                })
            })
            .catch(error => {
                console.log(error)
                next({ name: 'NetworkError' })
            })
    },
    beforeRouteUpdate (routeTo) {
        return EventService.getEvents(2, parseInt(routeTo.query.page) || 1)
            .then(response => {
                console.log('events: ', response.data)
                this.events = response.data
                this.totalEvent = response.headers['x-total-count'] // Told by the db
            })
            .catch(error => {
                console.log(error)
                return { name: 'NetworkError' }
            })
    },
    props: [ 'page' ],
    data: function () {
        return {
            events: null,
            totalEvent: 0,
            eventPerPage: 2
        }
    },
    computed: {
        hasNextPage: function () {
            console.log('Total event: ' + this.totalEvent)
            let totalPage = Math.ceil(this.totalEvent / this.eventPerPage)
            return this.page < totalPage
        }
    }
}
</script>

<style scoped>
.events {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pagination {
    display: flex;
    width: 290px;
}

.pagination a {
    flex: 1;
    text-decoration: none;
    color: #2c3e50;
}

#page-prev {
    text-align: left;
}

#page-next {
    text-align: right;
}
</style>
