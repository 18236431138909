import axios from 'axios'

const apiClient = axios.create({
    baseURL: 'https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
})

export default {
    getEvents (perPage, page) {
        return apiClient.get('/events?_limit=' + perPage + '&_page=' + page)
        // For example:
        // https://my-json-server.typicode.com/Code-Pop/Real-World_Vue-3/events?_limit=2&_page=2
    },
    getEvent (id) {
        return apiClient.get('/events/' + id)
    }
}
